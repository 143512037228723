import React from 'react';

export default function ChangeLocation({
  customerType,
  message,
  setMessage,
  localizedData,
  setShowChangeLocationModal,
  setShowLocationDetailsModal,
  handleSetShowChangeLocationModal,
  showChangeLocationModal,
  getLocationsByCurrentLocation,
  hasSearched,
  locationInput,
  locationInputRef,
  locations,
  resetLocationInput,
  submitForm,
  distances,
  handleLocationInputChange,
  handleSetFilter,
  filter,
  handleSetPreferredLocation,
  loading,
  loadingPreferredLocation,
  setRedirectAfterLocationSelected,
}) {
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  };

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[\s_-]+/g, '-');

  return (
    <div className={`${showChangeLocationModal ? '' : 'hidden'} change-location-wrapper fixed w-full h-full inset-0 z-50`}>
      <div className="flex h-full items-center justify-center px-4">
        <div onClick={() => setShowChangeLocationModal(false)} className="change-location-overlay fixed inset-0 h-full w-full cursor-pointer bg-black opacity-75"></div>
        <div className="relative w-full max-w-3xl rounded-t-lg bg-white shadow">
          <div className="from-nav-light to-nav-dark flex items-center justify-between rounded-t-lg bg-gradient-to-r p-4">
            <h2 className="font-secondary text-lg font-bold text-white">{localizedData.optionsFields.header_labels.find_a_location_modal_header}</h2>
            <div>
              <img
                className="change-location-close h-4 w-4 cursor-pointer"
                onClick={() => setShowChangeLocationModal(false)}
                onKeyPress={() => handleSetShowChangeLocationModal(event, false)}
                tabIndex={0}
                src={`${localizedData.themeUri}/dist/images/close.svg`}
                alt={localizedData.optionsFields.header_labels.my_location_close_alt_text}
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center py-8 px-4 md:px-16">
            <form onSubmit={(e) => submitForm(e)} className="w-full">
              <div className="mb-4">
                <label className="mr-4 mb-4 inline-block">
                  <input value="" onChange={(value) => handleSetFilter(value)} name="locationFilter" type="radio" className="mr-2" defaultChecked={true} />
                  <span>{localizedData.optionsFields.header_labels.all}</span>
                </label>
                <label className="mr-4 mb-4 inline-block">
                  <input value="c_retailServices" onChange={(value) => handleSetFilter(value)} name="locationFilter" type="radio" className="mr-2" />
                  <span>{localizedData.optionsFields.header_labels.retail}</span>
                </label>
                <label className="mr-4 mb-4 inline-block">
                  <input value="c_commercialServices" onChange={(value) => handleSetFilter(value)} name="locationFilter" type="radio" className="mr-2" defaultChecked={customerType === 'commercial'} />
                  <span>{localizedData.optionsFields.header_labels.commercial}</span>
                </label>
                <label className="mr-4 mb-4 inline-block">
                  <input value="stmfj" onChange={(value) => handleSetFilter(value)} name="locationFilter" type="radio" className="mr-2" />
                  <span>{localizedData.optionsFields.header_labels.southern_tire_mart_at_pilot_flying_j}</span>
                </label>
              </div>
              {localizedData.optionsFields.header_labels.location_search_label && <label htmlFor="locationModalTextInput">{localizedData.optionsFields.header_labels.location_search_label}</label>}
              <div className="border-medium-gray relative flex items-center border border-solid">
                <input
                  type="text"
                  onChange={handleLocationInputChange}
                  value={locationInput}
                  name="location"
                  ref={locationInputRef}
                  id="locationModalTextInput"
                  placeholder={localizedData.optionsFields.header_labels.find_a_location_form_placeholder}
                  className="h-full w-full px-4 py-2"
                />
                <button
                  type="submit"
                  className="search-submit from-primary-btn-light to-primary-btn-dark font-secondary absolute right-0 top-0 h-full w-16 cursor-pointer rounded-l-full bg-gradient-to-r px-4 py-2 font-bold uppercase text-white hover:no-underline"
                  value={localizedData.optionsFields.header_labels.search}
                >
                  {loading ? (
                    <img className="h-6 w-6 animate-spin" src={`${localizedData.themeUri}/dist/images/wheel.svg`} alt={localizedData.optionsFields.header_labels.loading} />
                  ) : (
                    <img className="h-6 w-6" src={`${localizedData.themeUri}/dist/images/search-white.svg`} alt={localizedData.optionsFields.header_labels.search} />
                  )}
                </button>
              </div>
            </form>
            {message && <div className="font-secondary text-secondary">{message}</div>}
            <div className="ml-auto mt-2">
              <a
                href="#"
                onClick={getLocationsByCurrentLocation}
                className="text-secondary ml-auto text-sm underline hover:no-underline"
                aria-description={localizedData.optionsFields.header_labels.use_your_location_aria_description}
              >
                {localizedData.optionsFields.header_labels.use_your_location} <img className="ml-2 inline-block h-4 w-4" src={`${localizedData.themeUri}/dist/images/your-location.svg`} alt="" />
              </a>
            </div>
          </div>

          {hasSearched && locationInput != '' ? (
            <>
              {locations && locations.length > 0 ? (
                <>
                  <div className="bg-light-gray h-72 overflow-x-hidden overflow-y-scroll pb-12 pt-8">
                    {locations.map((location, i) => (
                      <div key={`location-${i}`} className="-mx-2 flex flex-wrap px-4 py-2 md:px-16">
                        <div className="w-full px-2 md:w-1/2">
                          <h3 className="font-primary text-lg font-bold">
                            Southern Tire Mart {location.labels === 'STMPFJ' ? 'at Pilot' : location.labels === 'STM Wholesale' ? 'Wholesale' : ''}
                          </h3>
                          <address className="font-secondary not-italic">
                            <div>{location.address_line_1}</div>
                            <div>
                              {location.address_city}, {location.address_region} {location.address_postal_code}
                            </div>
                          </address>
                          {location.main_phone && (
                            <div className="font-secondary">
                              {localizedData.optionsFields.header_labels.phone}{' '}
                              <a
                                aria-description={`${localizedData.optionsFields.header_labels.call_store_aria_description} ${location.address_city}, ${location.address_region}`}
                                href={location.main_phone}
                              >
                                {formatPhoneNumber(location.main_phone)}
                              </a>
                            </div>
                          )}
                          <a
                            aria-description={`${localizedData.optionsFields.header_labels.get_directions_to_the_store_aria_description} ${location.address_city}, ${location.address_region}`}
                            href={`https://www.google.com/maps/place/${location.address_line_1},+${location.address_city},+${location.address_region}+${location.address_postal_code}/`}
                            target="_blank"
                          >
                            {localizedData.optionsFields.header_labels.my_location_get_directions}
                          </a>
                        </div>
                        <div className="flex w-full flex-col items-end justify-between px-2 md:w-1/2">
                          <div>
                            {location.distance} {localizedData.optionsFields.header_labels.miles}
                          </div>
                          {location.latitude && (
                            <div className="text-sm italic">
                              {location.latitude}, {location.longitude}
                            </div>
                          )}
                          <a
                            aria-description={`${localizedData.optionsFields.header_labels.view_details_for_the_store_aria_description} ${location.address_city}, ${location.address_region}`}
                            target="_blank"
                            href={`/locations/store-${location.entity_id}`}
                            className="block"
                          >
                            {localizedData.optionsFields.header_labels.view_store_details}
                          </a>
                          <div
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSetPreferredLocation(e);
                                setRedirectAfterLocationSelected(true);
                              }
                            }}
                            aria-description={`${localizedData.optionsFields.header_labels.make_my_preferred_location_the_store_aria_description} ${location.address_city}, ${location.address_region}`}
                            className="btn-stm btn-wheel from-primary-btn-light to-primary-btn-dark font-secondary relative block cursor-pointer rounded-r-full bg-gradient-to-r px-4 py-2 font-bold uppercase text-white hover:no-underline"
                          >
                            <div className="flex items-center">
                              <div className="mr-2">{localizedData.optionsFields.header_labels.make_this_my_store}</div>
                              <img src={`${localizedData.themeUri}/dist/images/wheel.svg`} className={`${loadingPreferredLocation ? 'animate-spin' : ''} inline-block w-6 h-6`} alt="" />
                              <div
                                className="absolute inset-0 h-full w-full cursor-pointer"
                                data-entity-id={location.entity_id}
                                onClick={(e) => {
                                  handleSetPreferredLocation(e);
                                  setRedirectAfterLocationSelected(true);
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <hr className="border-medium-gray mt-4 w-full border border-solid" />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-light-gray flex h-72 flex-col items-center justify-center p-4 md:px-16">
                    <h2 className="font-secondary font-bold">{localizedData.optionsFields.header_labels.no_locations_found}</h2>
                    <div className="flex items-center justify-between">
                      <a href="#" onClick={resetLocationInput} className="text-secondary mx-2 text-sm underline hover:no-underline">
                        {localizedData.optionsFields.header_labels.search_again}
                      </a>
                      <a href="#" onClick={getLocationsByCurrentLocation} className="text-secondary mx-2 text-sm underline hover:no-underline">
                        {localizedData.optionsFields.header_labels.no_locations_found}{' '}
                        <img className="ml-2 inline-block h-4 w-4" src={`${localizedData.themeUri}/dist/images/your-location.svg`} alt="" />
                      </a>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div
                className="h-72 w-full"
                style={{
                  backgroundImage: `url('${localizedData.themeUri}/dist/images/find-all-southern-tire-mart-locations.jpg')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              ></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
