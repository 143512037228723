import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function VehicleMake({ localizedData, yearValue, makeValue, setMakeValue }) {
  const [makes, setMakes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMake = async () => {
    if (yearValue) {
      setLoading(true);
      const { data } = await axios.get(`/wp-json/stm/v1/lookup/vehicle/makes?year=${yearValue}`);
      setMakes(data);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchMake();
  }, [yearValue]);
  return (
    <div className="w-full md:w-6/12">
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="brand"
        value={makeValue}
        disabled={loading}
        aria-label={localizedData.optionsFields.make}
        onChange={(e) => setMakeValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.make}</option>}
        {makes && makes.map((make) => {
          return (
            <option value={make.Manufacturer} key={make.Manufacturer}>
              {make.Manufacturer}
            </option>
          );
        })}
      </select>
    </div>
  );
}
