export default function careersSelect() {
    //https://stackoverflow.com/questions/5525071/how-to-wait-until-an-element-exists
    function waitForElm(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    waitForElm('#select2-search_categories-container').then(function(el) {
        var selectField = document.getElementById('select2-search_categories-container');
        var selectFieldText = selectField.querySelector('.select2-selection__placeholder').innerText;
        console.log(selectFieldText);
        selectField.setAttribute('aria-label', selectFieldText);
    })
}
