import React from 'react'

function FindALocationPortalButton({
    setShowChangeLocationModal,
    localizedData,
    btnColor
}) {
  
  return (
    <div 
    onClick={() => setShowChangeLocationModal(true)}
    aria-haspopup="true"
    tabIndex={0}
    className={`btn-stm btn-wheel relative hover:no-underline ${btnColor == 'blue' ? 'from-retail-cta-background-light to-retail-cta-background-dark' : 'from-primary-btn-light to-primary-btn-dark'} font-secondary inline-block cursor-pointer rounded-r-full bg-gradient-to-r px-6 py-4 font-bold uppercase text-white`} 
    >
        <div className="flex items-center justify-between">
            <div className="mr-2">{localizedData.optionsFields.header_labels.find_a_location_modal_header}</div>
            <img 
            className="h-8 w-8" 
            src={`${localizedData.themeUri}/dist/images/wheel.svg`}
            alt="" 
            />
        </div>
    </div>
  )
}

export default FindALocationPortalButton