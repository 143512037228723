import React from 'react';

export default function LocationDetails({
  cookies,
  localizedData,
  showLocationDetailsModal,
  setShowLocationDetailsModal,
  setShowChangeLocationModal,
  handleSetShowChangeLocationModal,
  handleSetShowLocationDetailsModal,
}) {
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  };

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[\s_-]+/g, '-');

  const date = new Date();
  const today = date.getDay();

  return (
    <>
      {cookies.stm_preferred_location && (
        <div className={`${showLocationDetailsModal ? '' : 'hidden'} location-details-wrapper fixed w-full h-full inset-0 overflow-x-hidden overflow-y-scroll z-50`}>
          <div className="flex justify-center px-4 py-12 md:py-24">
            <div onClick={() => setShowLocationDetailsModal(false)} className="location-details-overlay fixed inset-0 h-full w-full cursor-pointer bg-black opacity-75"></div>
            <div className="relative w-full max-w-6xl rounded-t-lg bg-white px-4 shadow">
              <div className="from-nav-light to-nav-dark -mx-4 flex items-center justify-between rounded-t-lg bg-gradient-to-r p-4">
                <h2 className="font-secondary text-lg font-bold text-white">{localizedData.optionsFields.header_labels.my_location_modal_header}</h2>
                <div>
                  <img
                    onClick={() => setShowLocationDetailsModal(false)}
                    onKeyPress={() => handleSetShowLocationDetailsModal(event, false)}
                    tabIndex={0}
                    className="location-details-close h-4 w-4 cursor-pointer"
                    src={`${localizedData.themeUri}/dist/images/close.svg`}
                    alt={localizedData.optionsFields.header_labels.my_location_close_alt_text}
                  />
                </div>
              </div>
              <div className="-mx-4 flex flex-wrap items-center py-8 px-4 md:px-16">
                <div className="w-full px-4 md:w-1/2">
                  {localizedData.optionsFields.header_labels.my_location_details && (
                    <h3 className="font-primary text-xl font-bold md:text-2xl">{localizedData.optionsFields.header_labels.my_location_details}</h3>
                  )}
                  <address className="mb-4 not-italic">
                    <div>{cookies.stm_preferred_location.address_line_1}</div>
                    <div>
                      {cookies.stm_preferred_location.address_city}, {cookies.stm_preferred_location.address_region} {cookies.stm_preferred_location.address_postal_code}
                    </div>
                  </address>
                  {cookies.stm_preferred_location.latitude && cookies.stm_preferred_location.longitude && (
                    <div className="mb-4 text-sm italic">
                      {cookies.stm_preferred_location.latitude}, {cookies.stm_preferred_location.longitude}
                    </div>
                  )}
                  <div className="mb-4">
                    <a
                      className="hover:underline"
                      target="_blank"
                      href={`https://www.google.com/maps/place/${cookies.stm_preferred_location.address_line_1},+${cookies.stm_preferred_location.address_city},+${cookies.stm_preferred_location.address_region}+${cookies.stm_preferred_location.address_postal_code}/`}
                    >
                      {localizedData.optionsFields.header_labels.my_location_get_directions}
                    </a>
                  </div>
                  <div className="mb-4">
                    {cookies.stm_preferred_location.main_phone && (
                      <div>
                        <a href={`tel:${formatPhoneNumber(cookies.stm_preferred_location.main_phone)}`}>{formatPhoneNumber(cookies.stm_preferred_location.main_phone)}</a>
                      </div>
                    )}
                    {cookies.stm_preferred_location.emails && (
                      <a className="hover:underline" href={`mailto:${cookies.stm_preferred_location.emails}`}>
                        {cookies.stm_preferred_location.emails}
                      </a>
                    )}
                  </div>
                  <a
                    href="#"
                    onClick={() => {
                      setShowLocationDetailsModal(false);
                      setShowChangeLocationModal(true);
                    }}
                    className="btn-stm btn-wheel from-primary-btn-light to-primary-btn-dark font-secondary mb-4 inline-block cursor-pointer rounded-r-full bg-gradient-to-r px-4 py-2 text-sm font-bold uppercase text-white hover:no-underline md:mb-0 md:text-base"
                  >
                    <div className="flex items-center">
                      <div className="mr-2">{localizedData.optionsFields.header_labels.my_location_change_location}</div>
                      <img src={`${localizedData.themeUri}/dist/images/wheel.svg`} className="h-8 w-8" alt="" />
                    </div>
                  </a>
                </div>
                <div className="w-full px-4 md:w-1/2">
                  <img className="hidden h-auto w-full md:block" src={`${localizedData.themeUri}/dist/images/find-all-southern-tire-mart-locations.jpg`} alt="" />
                </div>
              </div>
              <div className="bg-medium-gray -mx-4 flex flex-wrap py-8 px-4 md:px-16">
                <div className="w-full px-4 md:w-1/2">
                  {localizedData.optionsFields.header_labels.my_location_hours && (
                    <h3 className="font-primary text-xl font-bold md:text-2xl">{localizedData.optionsFields.header_labels.my_location_hours}</h3>
                  )}
                  <div className="mb-4 rounded bg-white p-4 shadow-lg">
                    <table className="w-full">
                      <tbody>
                        {cookies.stm_preferred_location.hours_monday && (
                          <tr className={`${today === 1 && 'bg-light-gray font-bold'}`}>
                            <td className="p-2">{localizedData.optionsFields.header_labels.monday}</td>
                            <td className="p-2">
                              {typeof cookies.stm_preferred_location.hours_monday === 'string'
                                ? cookies.stm_preferred_location.hours_monday
                                : `${cookies.stm_preferred_location.hours_monday[0]} - ${cookies.stm_preferred_location.hours_monday[1]}`}
                            </td>
                          </tr>
                        )}
                        {cookies.stm_preferred_location.hours_tuesday && (
                          <tr className={`${today === 2 && 'bg-light-gray font-bold'}`}>
                            <td className="p-2">{localizedData.optionsFields.header_labels.tuesday}</td>
                            <td className="p-2">
                              {typeof cookies.stm_preferred_location.hours_tuesday === 'string'
                                ? cookies.stm_preferred_location.hours_tuesday
                                : `${cookies.stm_preferred_location.hours_tuesday[0]} - ${cookies.stm_preferred_location.hours_tuesday[1]}`}
                            </td>
                          </tr>
                        )}
                        {cookies.stm_preferred_location.hours_wednesday && (
                          <tr className={`${today === 3 && 'bg-light-gray font-bold'}`}>
                            <td className="p-2">{localizedData.optionsFields.header_labels.wednesday}</td>
                            <td className="p-2">
                              {typeof cookies.stm_preferred_location.hours_wednesday === 'string'
                                ? cookies.stm_preferred_location.hours_wednesday
                                : `${cookies.stm_preferred_location.hours_wednesday[0]} - ${cookies.stm_preferred_location.hours_wednesday[1]}`}
                            </td>
                          </tr>
                        )}
                        {cookies.stm_preferred_location.hours_thursday && (
                          <tr className={`${today === 4 && 'bg-light-gray font-bold'}`}>
                            <td className="p-2">{localizedData.optionsFields.header_labels.thursday}</td>
                            <td className="p-2">
                              {typeof cookies.stm_preferred_location.hours_thursday === 'string'
                                ? cookies.stm_preferred_location.hours_thursday
                                : `${cookies.stm_preferred_location.hours_thursday[0]} - ${cookies.stm_preferred_location.hours_thursday[1]}`}
                            </td>
                          </tr>
                        )}
                        {cookies.stm_preferred_location.hours_friday && (
                          <tr className={`${today === 5 && 'bg-light-gray font-bold'}`}>
                            <td className="p-2">{localizedData.optionsFields.header_labels.friday}</td>
                            <td className="p-2">
                              {typeof cookies.stm_preferred_location.hours_friday === 'string'
                                ? cookies.stm_preferred_location.hours_friday
                                : `${cookies.stm_preferred_location.hours_friday[0]} - ${cookies.stm_preferred_location.hours_friday[1]}`}
                            </td>
                          </tr>
                        )}
                        {cookies.stm_preferred_location.hours_saturday && (
                          <tr className={`${today === 6 && 'bg-light-gray font-bold'}`}>
                            <td className="p-2">{localizedData.optionsFields.header_labels.saturday}</td>
                            <td className="p-2">
                              {typeof cookies.stm_preferred_location.hours_saturday === 'string'
                                ? cookies.stm_preferred_location.hours_saturday
                                : `${cookies.stm_preferred_location.hours_saturday[0]} - ${cookies.stm_preferred_location.hours_saturday[1]}`}
                            </td>
                          </tr>
                        )}
                        {cookies.stm_preferred_location.hours_sunday && (
                          <tr className={`${today === 0 && 'bg-light-gray font-bold'}`}>
                            <td className="p-2">{localizedData.optionsFields.header_labels.sunday}</td>
                            <td className="p-2">
                              {typeof cookies.stm_preferred_location.hours_sunday === 'string'
                                ? cookies.stm_preferred_location.hours_sunday
                                : `${cookies.stm_preferred_location.hours_sunday[0]} - ${cookies.stm_preferred_location.hours_sunday[1]}`}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="w-full px-4 md:w-1/2">
                  {cookies.stm_preferred_location.commercial_services && cookies.stm_preferred_location.commercial_services.length > 0 ? (
                    <>
                      <h3 className="font-primary text-xl font-bold md:text-2xl">{localizedData.optionsFields.header_labels.my_location_services}</h3>
                      <ul className="wp-block- mb-2 list-outside list-disc pl-4">
                        {Array.isArray(cookies.stm_preferred_location.commercial_services[0])
                          ? // If commercial_services[0] is an array, iterate over its elements
                            cookies.stm_preferred_location.commercial_services[0].map((service, key) => (
                              <li className="text-sm" key={`service-${key}`}>
                                {service.replaceAll('"', '')}
                              </li>
                            ))
                          : // If services[0] is not an array, split the string and iterate over its elements
                            cookies.stm_preferred_location.commercial_services.split(',').map((service, key) => (
                              <li className="w-full pr-6 text-sm " key={`service-${key}`}>
                                {service.replaceAll('"', '')}
                              </li>
                            ))}
                      </ul>
                    </>
                  ) : cookies.stm_preferred_location.retail_services && cookies.stm_preferred_location.retail_services.length > 0 ? (
                    <>
                      <h3 className="font-primary text-xl font-bold md:text-2xl">{localizedData.optionsFields.header_labels.my_location_services}</h3>
                      <ul className="wp-block- mb-2 list-outside list-disc pl-4">
                        {Array.isArray(cookies.stm_preferred_location.retail_services[0])
                          ? // If retail_services[0] is an array, iterate over its elements
                            cookies.stm_preferred_location.retail_services[0].map((service, key) => (
                              <li className="text-sm" key={`service-${key}`}>
                                {service.replaceAll('"', '')}
                              </li>
                            ))
                          : // If services[0] is not an array, split the string and iterate over its elements
                            cookies.stm_preferred_location.retail_services.split(',').map((service, key) => (
                              <li className="w-full pr-6 text-sm " key={`service-${key}`}>
                                {service.replaceAll('"', '')}
                              </li>
                            ))}
                      </ul>
                    </>
                  ) : cookies.stm_preferred_location.services && cookies.stm_preferred_location.services.length > 0 ? (
                    <>
                      <h3 className="font-primary text-xl font-bold md:text-2xl">{localizedData.optionsFields.header_labels.my_location_services}</h3>
                      <ul className="wp-block- mb-2 list-outside list-disc pl-4">
                        {Array.isArray(cookies.stm_preferred_location.services[0])
                          ? // If services[0] is an array, iterate over its elements
                            cookies.stm_preferred_location.services[0].map((service, key) => (
                              <li className="text-sm" key={`service-${key}`}>
                                {service.replaceAll('"', '')}
                              </li>
                            ))
                          : // If services[0] is not an array, split the string and iterate over its elements
                            cookies.stm_preferred_location.services.split(',').map((service, key) => (
                              <li className="w-full pr-6 text-sm " key={`service-${key}`}>
                                {service.replaceAll('"', '')}
                              </li>
                            ))}
                      </ul>
                    </>
                  ) : null}
                  <a className="hover:underline" target="_blank" href={`/locations/store-${cookies.stm_preferred_location.entity_id}`}>
                    {localizedData.optionsFields.header_labels.my_location_see_complete_details}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
