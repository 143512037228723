import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function VehicleSizeCommercial({ localizedData, sizeValue, setsizeValue, applicationsValue, positionValue }) {
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSizes = async () => {
    setLoading(true);
    const { data } = await axios.get(`/wp-json/stm/v1/lookup/veh-commercial/size?application=${applicationsValue}&position=${positionValue}`);
    setSizes(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSizes();
  }, [positionValue]);

  return (
    <div className="w-full">
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="Size"
        value={sizeValue}
        disabled={loading}
        onChange={(e) => setsizeValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.size}</option>}
        {sizes && sizes.map((size) => {
          return (
            <option value={size} key={size}>
              {size}
            </option>
          );
        })}
      </select>
    </div>
  );
}
