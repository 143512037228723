import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function TireRim({ localizedData, widthValue, aspectRatioValue, rimValue, setrimValue }) {
  const [rims, setRims] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSizes = async () => {
    setLoading(true);
    const { data } = await axios.get(`/wp-json/stm/v1/lookup/tire/rim?width=${widthValue}&ar=${aspectRatioValue}`);
    setRims(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSizes();
  }, [aspectRatioValue]);

  return (
    <div className="w-full">
      <label htmlFor="tireRimWidth" className="sr-only">{localizedData.optionsFields.rim_size}</label>
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="Tire Width"
        id="tireRimWidth"
        value={rimValue}
        disabled={loading}
        aria-label={localizedData.optionsFields.rim_size}
        onChange={(e) => setrimValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.rim_size}</option>}
        {rims && rims.map((rim) => {
          return (
            <option value={rim} key={rim}>
              {rim}
            </option>
          );
        })}
      </select>
    </div>
  );
}
