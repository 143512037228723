import React, {useState} from 'react'

function CustomCheckoutButton({
  cookies,
  localizedData,
  setShowChangeLocationModal,
  handleSetShowChangeLocationModal
}) {
  const [checkoutButtonWheelSpin, setCheckoutButtonWheelSpin] = useState(false);
  
  return (
    <>
      {cookies.stm_preferred_location ? (
      <a
      href={localizedData.optionsFields.custom_checkout_button_link.url}
      target={localizedData.optionsFields.custom_checkout_button_link.target}
      onClick={() => setCheckoutButtonWheelSpin(true)}
      className="btn-stm btn-wheel relative hover:no-underline from-primary-btn-light to-primary-btn-dark font-secondary inline-block cursor-pointer rounded-r-full bg-gradient-to-r px-6 py-4 font-bold uppercase text-white" 
      >
          <div className="flex items-center justify-between">
              <div className="mr-2">{localizedData.optionsFields.custom_checkout_button_link.title}</div>
              <img 
              className={`${checkoutButtonWheelSpin ? 'animate-spin' : ''} h-8 w-8`}
              src={`${localizedData.themeUri}/dist/images/wheel.svg`}
              alt="" 
              />
          </div>
      </a>
      ) : (
        <div
        onClick={() => setShowChangeLocationModal(true)}
        onKeyDown={() => handleSetShowChangeLocationModal(event, true)}
        aria-haspopup="true"
        tabIndex={0}
        className="relative hover:no-underline from-primary-btn-light to-primary-btn-dark font-secondary inline-block cursor-pointer rounded-r-full bg-gradient-to-r px-6 py-4 font-bold uppercase text-white" 
        >
            <div className="flex items-center justify-between">
                <div className="mr-2">{localizedData.optionsFields.cart_translation_strings.please_choose_location}</div>
                <img 
                className="h-8 w-8" 
                src={`${localizedData.themeUri}/dist/images/wheel.svg`}
                alt="" 
                />
            </div>
        </div>
      )}
    </>
  )
}

export default CustomCheckoutButton