import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function VehicleSize({ localizedData, sizeValue, setSizeValue, Chassis, Model, optionValue }) {
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSize = async () => {
    setLoading(true);
    const { data } = await axios.get(`/wp-json/stm/v1/lookup/vehicle/products?modelId=${Model}&chassisID=${Chassis}`);
    setSizes(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSize();
  }, [optionValue]);

  return (
    <div className="w-full md:w-6/12 md:pr-4">
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="brand"
        value={sizeValue}
        disabled={loading}
        aria-label={localizedData.optionsFields.size}
        onChange={(e) => setSizeValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.size}</option>}
        {sizes && sizes.map((size) => {
          return (
            <option value={size.value} key={size.value}>
              {size.displayName}
            </option>
          );
        })}
      </select>
    </div>
  );
}
