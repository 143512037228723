import React, { useState, useEffect } from 'react';

import TireBrand from './TireBrand';
import TireSize from './TireSize';
import TireVehicle from './TireVehicle';

interface Props {
  preselectedType: string;
}

export default function TireLookup(props: Props) {
  const { preselectedType } = props;

  const [tabSelected, settabSelected] = useState('Vehicle');
  const { localizedData } = window;

  const handleSetTabSelected = (value) => {
    const tireLookup = document.getElementById('tire-lookup');
    const yOffset = -200;
    const y = tireLookup.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    settabSelected(value);

    const lookupMin = document.querySelector('.lookup-min');
    if (lookupMin) {
      lookupMin.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    if (e.key === 'Enter') {
      handleSetTabSelected(value);
    }
  };

  return (
    <div id="tire-lookup">
      <div className="h-full w-full justify-center text-black shadow">
        <div className="text-dark-gray flex justify-center text-center font-bold" role="tablist">
          <div
            role="tab"
            onClick={() => handleSetTabSelected('Vehicle')}
            onKeyDown={(e) => handleKeyDown(e, 'Vehicle')}
            className={`${tabSelected === 'Vehicle' ? 'bg-white' : 'bg-medium-gray'} w-1/3 cursor-pointer rounded-t-lg p-2 shadow-inner`}
            aria-selected={tabSelected === 'Vehicle'}
            tabIndex={0}
          >
            {localizedData.optionsFields.by_vehicle}
          </div>
          <div
            role="tab"
            onClick={() => handleSetTabSelected('TireSize')}
            onKeyDown={(e) => handleKeyDown(e, 'TireSize')}
            className={`${tabSelected === 'TireSize' ? 'bg-white' : 'bg-medium-gray'} w-1/3 cursor-pointer rounded-t-lg p-2 shadow-inner`}
            aria-selected={tabSelected === 'TireSize'}
            tabIndex={0}
          >
            {localizedData.optionsFields.by_tire_size}
          </div>
          <div
            role="tab"
            onClick={() => handleSetTabSelected('Brand')}
            onKeyDown={(e) => handleKeyDown(e, 'Brand')}
            className={`${tabSelected === 'Brand' ? 'bg-white' : 'bg-medium-gray'} w-1/3 cursor-pointer rounded-t-lg p-2 shadow-inner`}
            aria-selected={tabSelected === 'Brand'}
            tabIndex={0}
          >
            {localizedData.optionsFields.by_brand}
          </div>
        </div>
        <div className="lookup-min relative bg-white p-10" tabIndex={0}>
          {tabSelected === 'Vehicle' && <TireVehicle preselectedType={preselectedType} />}
          {tabSelected === 'TireSize' && <TireSize preselectedType={preselectedType} />}
          {tabSelected === 'Brand' && <TireBrand preselectedType={preselectedType} />}
        </div>
      </div>
    </div>
  );
}
