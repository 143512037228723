import 'vite/modulepreload-polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';

import YourLocation from './components/LocationPicker/YourLocation';
import TireLookup from './components/TireLookup/TireLookup';
import TireModelLookup from './components/TireLookup/TireModelLookup';
// import CommercialAccountForm from './components/CommercialAccount/CommercialAccountForm';

const YourLocationDiv = document.getElementById('YourLocation');
const { customerType } = YourLocationDiv.dataset;
const YourLocationRoot = YourLocationDiv && createRoot(YourLocationDiv);
if (YourLocationRoot) {
  YourLocationRoot.render(<YourLocation customerType={customerType} />);
}

const TireLookupDiv = document.getElementById('TireLookup');
const TireLookupRoot = TireLookupDiv && createRoot(TireLookupDiv);
if (TireLookupRoot) {
  TireLookupRoot.render(<TireLookup preselectedType={TireLookupDiv.dataset.type} />);
  TireLookupDiv.classList.add('loaded');
}

const TireModelLookupDiv = document.getElementById('TireModelLookup');
const TireModelLookupRoot = TireModelLookupDiv && createRoot(TireModelLookupDiv);
if (TireModelLookupRoot) {
  TireModelLookupRoot.render(<TireModelLookup preselectedType={TireModelLookupDiv.dataset.type} productsJson={TireModelLookupDiv.dataset.products} />);
  TireModelLookupDiv.classList.add('loaded');
}

// const CommercialAccountDiv = document.getElementById('CommercialAccountForm');
// const CommercialAccountRoot = CommercialAccountDiv && createRoot(CommercialAccountDiv);
// if (CommercialAccountRoot) {
//   CommercialAccountRoot.render(<CommercialAccountForm />);
//   CommercialAccountDiv.classList.add('loaded');
// }
