import React from 'react';

import DownloadPDF from './DownloadPDF';

function YourLocationMobile({
  cookies,
  localizedData,
  setShowChangeLocationModal,
  setShowLocationDetailsModal,
  showChangeLocationModal,
  getLocationsByCurrentLocation,
  hasSearched,
  locationInput,
  locationInputRef,
  locations,
  resetLocationInput,
  submitForm,
  handleLocationInputChange,
  handleSetFilter,
  handleSetPreferredLocation,
  distances,
  loading,
  loadingPreferredLocation,
  setRedirectAfterLocationSelected,
}) {
  return (
    <div className="relative flex">
      {loadingPreferredLocation && (
        <div className="absolute inset-0 z-30 flex h-full w-full items-center justify-center bg-white opacity-75">
          <img className="h-6 w-6 animate-spin" src={`${localizedData.themeUri}/dist/images/wheel-red.svg`} alt={localizedData.optionsFields.header_labels.loading} />
        </div>
      )}
      <div className="flex w-full items-center justify-between">
        <div className={`${cookies.stm_preferred_location ? 'w-5/12' : 'justify-between font-bold w-full'} flex flex-wrap items-center justify-between `}>
          <div className="font-secondary relative flex items-center font-bold">
            <img className="mr-2 h-4 w-4" src={`${localizedData.themeUri}/dist/images/your-location.svg`} alt="" />
            <a onClick={() => setShowChangeLocationModal(true)} aria-haspopup="true" tabIndex={0} href="#" className="change-location-trigger text-secondary text-xs underline hover:no-underline">
              {cookies.stm_preferred_location ? localizedData.optionsFields.header_labels.your_location_change : localizedData.optionsFields.header_labels.find_a_location_modal_header}
            </a>
          </div>

          <div className={`${cookies.stm_preferred_location ? ' text-xxs' : 'text-xs'}`}>
            {localizedData.optionsFields.locations_map && (
              <a
                href={`${localizedData.optionsFields.locations_map.url}`}
                target={`${localizedData.optionsFields.locations_map.target}`}
                className={`${cookies.stm_preferred_location ? ' text-xxs w-full' : 'text-xs'} text-secondary underline hover:no-underline mb-1 inline-block`}
              >
                {localizedData.optionsFields.locations_map.title}
              </a>
            )}

            {!cookies.stm_preferred_location && <DownloadPDF localizedData={localizedData} />}
          </div>

          {cookies.stm_preferred_location && <DownloadPDF localizedData={localizedData} />}
        </div>
        {cookies.stm_preferred_location && (
          <>
            <div>
              <address className="font-secondary text-xs not-italic leading-none md:text-xs">
                <div className="font-light">{cookies.stm_preferred_location.address_line_1}</div>
                <div className="font-light">
                  {cookies.stm_preferred_location.address_city}, {cookies.stm_preferred_location.address_region} {cookies.stm_preferred_location.address_postal_code}
                </div>
              </address>
            </div>
            <div className="flex flex-col justify-center">
              <div className="font-secondary text-xs font-light leading-none">
                {Array.isArray(cookies.stm_preferred_location.labels) &&
                  cookies.stm_preferred_location.labels.filter((label) => label.includes('Cat')).map((category, index) => (index ? ', ' : '') + category.replace('Cat - ', ''))}
              </div>
              <a
                onClick={() => setShowLocationDetailsModal(true)}
                aria-haspopup="true"
                tabIndex={0}
                href="#"
                className="location-details-trigger font-secondary text-secondary flex items-center text-xs leading-none hover:underline"
              >
                <div className="mr-2">{localizedData.optionsFields.header_labels.my_location_hours_and_details}</div>
                <img className="h-3 w-3" src={`${localizedData.themeUri}/dist/images/chevron-down.svg`} />
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default YourLocationMobile;
