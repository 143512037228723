class FileField {
    constructor() {
        this.events();
    }

    events() {
        this.makeCVRequired();
    }

    makeCVRequired() {
        let cvField = $('.required-field input[type="file"]')

        cvField.attr('required', true);
    }
}

export default FileField;