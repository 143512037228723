import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function VehicleApplication({ localizedData, applicationsValue, setapplicationsValue }) {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchApplications = async () => {
    setLoading(true);
    const { data } = await axios.get('/wp-json/stm/v1/lookup/veh-commercial/application');
    setApplications(data);
    setapplicationsValue('- Any Vehicle Type -');
    setLoading(false);
  };

  useEffect(() => {
    fetchApplications();
  }, [setApplications]);

  return (
    <div className="w-full">
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="Vehicle Type"
        value={applicationsValue}
        disabled={loading}
        aria-label={localizedData.optionsFields.vehicle_type}
        onChange={(e) => setapplicationsValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="- Any Vehicle Type -">{localizedData.optionsFields.any_vehicle_type}</option>}
        {applications && applications.map((application) => {
          return (
            <option value={application} key={application}>
              {application}
            </option>
          );
        })}
      </select>
    </div>
  );
}
