import React from 'react';

function MakeThisMyStorePortalText({
  handleSetPreferredLocation,
  localizedData,
  index,
  cookies
}) {
  const buttonRoot = document.getElementById(
    `MakeThisMyStorePortalTextDOMNode${index}`
  );

  return (
    <>
      {cookies.stm_preferred_location?.entity_id !== buttonRoot.dataset.entityId ? (
        <span
          onClick={(e) => handleSetPreferredLocation(e)}
          data-entity-id={buttonRoot.dataset.entityId}
          className="font-secondary text-primary inline-block cursor-pointer hover:underline"
        >
          {localizedData.optionsFields.header_labels.make_this_my_store}
        </span>
      ) : localizedData.optionsFields.header_labels.your_location}
    </>
  );
}

export default MakeThisMyStorePortalText;
