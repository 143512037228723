import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function TireAspectRatio({ localizedData, widthValue, aspectRatioValue, setaspectRatioValue }) {
  const [aspects, setAspects] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSizes = async () => {
    setLoading(true);
    const { data } = await axios.get(`/wp-json/stm/v1/lookup/tire/aspect-ratio?width=${widthValue}`);
    setAspects(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSizes();
  }, [widthValue]);

  return (
    <div className="w-full">
      <label htmlFor="tireAspectRatio" className="sr-only">
        {localizedData.optionsFields.aspect_ratio}
      </label>
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="Tire Width"
        id="tireAspectRatio"
        value={aspectRatioValue}
        disabled={loading}
        tabIndex={0}
        aria-label={localizedData.optionsFields.aspect_ratio}
        onChange={(e) => setaspectRatioValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.aspect_ratio}</option>}
        {aspects && aspects.map((aspect) => {
          return (
            <option value={aspect} key={aspect}>
              {aspect}
            </option>
          );
        })}
      </select>
    </div>
  );
}
