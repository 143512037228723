import $ from 'jquery';

export function Header() {
  let lastScrollTop;
  const header = document.getElementById('mainHeader');

  window.addEventListener('scroll', function() {
    if (window.innerWidth <= 1280 && window.innerHeight <= 700) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollbarOffset = document.querySelector('.scrollbar-offset').offsetHeight;
      
      if(scrollTop > lastScrollTop && scrollTop > 50) {
        header.style.transform=`translateY(-${scrollbarOffset - 15}px)`;
      } else {
        header.style.transform='none';
      }
      
      lastScrollTop = scrollTop;
    }
  });
}

export function Search() {
  const headerSearchIcon = $('.header-search-icon');
  const headerSearchFormWrapper = $('.header-search-form-wrapper');
  const overlay = $('.search-overlay');
  const menuIcon = $('.menu-icon');

  function showSearch() {
    headerSearchFormWrapper.stop(true).fadeIn(300);
    overlay.stop(true).fadeIn(300);
    menuIcon.removeClass('z-50'); // prevent stacking issues
    headerSearchIcon.attr('aria-expanded', 'true');
    overlay.attr('aria-expanded', 'true');
    document.getElementsByTagName('html')[0].classList.add('overflow-hidden');
    $('header .search-field').focus();
  }

  function hideSearch() {
    headerSearchFormWrapper.stop(true).fadeOut(300);
    overlay.stop(true).fadeOut(300);
    menuIcon.addClass('z-50');
    headerSearchIcon.attr('aria-expanded', 'false');
    overlay.attr('aria-expanded', 'false');
    document.getElementsByTagName('html')[0].classList.remove('overflow-hidden');
    $('header .search-field').blur();
  }

  headerSearchIcon.on('click', () => {
    if (headerSearchIcon.attr('aria-expanded') === 'true') {
      hideSearch();
    } else {
      showSearch();
    }
  });

  headerSearchIcon.on('keypress', function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (headerSearchIcon.attr('aria-expanded') === 'true') {
        hideSearch();
      } else {
        showSearch();
      }
    }
  });

  overlay.on('click', () => {
    hideSearch();
  });

  overlay.on('keypress', function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      hideSearch();
    }
  });
}

export function Menu() {
  const menuIcon = $('.menu-icon-click-area');
  const nav = $('.nav-main-wrapper');
  const overlay = $('.navigation-panel-overlay');
  const menuIconBar = $('.menu-icon-bar');
  const menuIconBarTop = $('.menu-icon-bar--top');
  const menuIconBarMiddle = $('.menu-icon-bar--middle');
  const menuIconBarBottom = $('.menu-icon-bar--bottom');
  const menuIconText = $('.menu-icon-text');
  const navLinks = $('#navigation-panel a');
  let focusableElements = null;
  let firstFocusableElement = null;
  let lastFocusableElement = null;

  // Make nav links untabbable if menu is closed
  $(document).ready(function () {
    navLinks.attr('tabindex', -1);
  })
  navLinks.attr('tabindex', -1);

  // Get focusable elements in the menu
  function setFocusableElements() {
    focusableElements = nav.find('a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])').add(menuIcon);
    firstFocusableElement = focusableElements[0];
    lastFocusableElement = focusableElements[focusableElements.length - 1];
  }

  function handleKeydown(e) {
    if (e.key === 'Tab') {
      if (e.shiftKey) { // Shift + Tab
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else { // Tab
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    }

    if (e.key === 'Escape') {
      hideMenu();
    }
  }

  function showMenu() {
    setFocusableElements();
    overlay.addClass('md:block');
    document.getElementsByTagName('html')[0].classList.add('overflow-hidden');
    menuIconBar.addClass('md:bg-white');
    menuIconText.removeClass('text-primary').addClass('text-white');
    nav.removeClass('translate-x-full');
    menuIconBarMiddle.hide();
    menuIconBarTop.addClass('rotate-45 translate-y-3');
    menuIconBarBottom.addClass('-rotate-45 translate-y--4');
    menuIcon.attr('aria-expanded', 'true');
    navLinks.attr('tabindex', 0);
    firstFocusableElement.focus(); // Focus on the first focusable element
    document.addEventListener('keydown', handleKeydown);
  }

  function hideMenu() {
    overlay.removeClass('md:block');

    document.getElementsByTagName('html')[0].classList.remove('overflow-hidden');
    menuIconBar.removeClass('md:bg-white');
    menuIconText.addClass('text-primary').removeClass('text-white');
    nav.addClass('translate-x-full');
    menuIconBarMiddle.show();
    menuIconBarTop.removeClass('rotate-45 translate-y-3');
    menuIconBarBottom.removeClass('-rotate-45 translate-y--4');
    menuIcon.attr('aria-expanded', 'false');
    navLinks.attr('tabindex', -1);
    document.removeEventListener('keydown', handleKeydown);
  }

  menuIcon.on('click', () => {
    if (nav.hasClass('translate-x-full')) {
      showMenu();
    } else {
      hideMenu();
    }
  });

  menuIcon.on('keypress', function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (nav.hasClass('translate-x-full')) {
        showMenu();
      } else {
        hideMenu();
      }
    }
  });

  overlay.on('click', () => {
    hideMenu();
  });
}
