import $ from 'jquery';

export default function filterBar() {
  const filterHeader = $('.filter-bar__header');
  const clearFilters = $('.clear-filters');
  const form = $('#filtersForm');
  const infoIndicator = $('.filter-info-indicator');
  const closeIcon = $('.filter-info-close');
  const infoOverlay = $('.filter-info-overlay');
  const filtersForm = $('#filtersForm');

  infoOverlay.on('click', function () {
    const infoModal = $('.filter-info-modal');
    infoModal.stop(true).fadeOut(215);
    infoOverlay.stop(true).fadeOut(215);
    $('html, body').removeClass('overflow-hidden');
  });

  closeIcon.on('click', function (e) {
    e.stopPropagation();
    const infoModal = $(this).parents('.filter-info-modal');
    infoModal.stop(true).fadeOut(215);
    infoOverlay.stop(true).fadeOut(215);
    $('html, body').removeClass('overflow-hidden');
  });

  infoIndicator.on('click', function (e) {
    e.stopPropagation();
    const infoModal = $(this).next('.filter-info-modal');
    infoModal.stop(true).fadeIn(215);
    infoOverlay.stop(true).fadeIn(215);
    $('html, body').addClass('overflow-hidden');
  });

  filterHeader.on('click', function () {
    const filterContent = $(this).next('.filter-bar__content');

    filterContent.stop(true).slideToggle(215);
  });

  clearFilters.on('click', function () {
    const filters = $(this).next().find('input[type=checkbox]');

    if ($(this).next().find('input:checked').length > 0) {
      filters.prop('checked', false);
      form.submit();
    }
  });

  filtersForm.on('submit', function (e) {
    $(this).find('button[type="submit"]').addClass('loading');
  });
}
