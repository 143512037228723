import axios from 'axios';
import React, { useEffect, useState } from 'react';

import Image from '../ReactImageEnlarger';
import TireAspectRatio from './TireAspectRatio';
import TireRim from './TireRim';
import TireWidth from './TireWidth';

export default function TireSize(props) {
  const { preselectedType, onSelectSize, showSubmitButton = true } = props;
  const { localizedData } = window;
  const [tire, setTire] = useState([]);
  const [brandType, setbrandType] = useState(preselectedType || 'Retail');
  const [loading, setLoading] = useState(true);
  const [submitting, setsubmitting] = useState(false);
  const [tireValue, settireValue] = useState('');
  const [widthValue, setwidthValue] = useState('');
  const [aspectRatioValue, setaspectRatioValue] = useState('');
  const [rimValue, setrimValue] = useState('');
  const [zoomed, setZoomed] = useState(false);

  const handleSubmitForm = async () => {
    setsubmitting(true);
    const { data: termOptions } = await axios.get(`/wp-json/stm/v1/lookup/tire/term-options?width=${widthValue}&ar=${aspectRatioValue}&rim=${rimValue}`);
    window.location.href = `${termOptions}?type=${brandType}&rim_width=${rimValue}&section_width=${widthValue}&aspect_ratio=${aspectRatioValue}`;
  };

  const HandleBrandType = (e) => {
    setbrandType(e.target.value);
  };

  const handleSelectTrimValue = (value: string) => {
    setrimValue(value);
    if (onSelectSize && typeof onSelectSize === 'function') {
      onSelectSize({
        aspectRatio: aspectRatioValue,
        width: widthValue,
        rim: value,
      });
    }
  };

  useEffect(() => {
    setwidthValue('');
    setaspectRatioValue('');
    setrimValue('');
  }, [brandType]);

  useEffect(() => {
    setaspectRatioValue('');
    setrimValue('');
  }, [widthValue]);

  useEffect(() => {
    setrimValue('');
  }, [aspectRatioValue]);

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        {!preselectedType && (
          <div className="mb-4">
            <label className="mr-4 mb-4 inline-block">
              <input value="Retail" checked={brandType.toLowerCase() === 'retail'} onChange={(value) => HandleBrandType(value)} type="radio" className="mr-2" />
              <span>{localizedData.optionsFields.car_truck_suv}</span>
            </label>
            <label className="mr-4 mb-4 inline-block">
              <input value="Commercial" checked={brandType.toLowerCase() === 'commercial'} onChange={(value) => HandleBrandType(value)} type="radio" className="mr-2" />
              <span>{localizedData.optionsFields.commercial_fleet_rv}</span>
            </label>
          </div>
        )}
        <div className="flex w-full flex-wrap">
          <div className=" w-full md:w-1/2 ">
            <Image
              style={{ width: '500px', height: 'auto' }}
              zoomed={zoomed}
              src={localizedData.optionsFields.tire_size_image.url}
              alt={localizedData.optionsFields.tire_size_image.alt}
              onClick={() => setZoomed(true)}
              onRequestClose={() => setZoomed(false)}
            />
          </div>
          <div className=" w-full md:w-1/2">
            <TireWidth localizedData={localizedData} widthValue={widthValue} setwidthValue={setwidthValue} type={brandType} />

            {widthValue ? (
              <TireAspectRatio localizedData={localizedData} widthValue={widthValue} aspectRatioValue={aspectRatioValue} setaspectRatioValue={setaspectRatioValue} />
            ) : (
              <div className="w-full">
                <label htmlFor="tireSizeAspectRatio" className="sr-only">
                  {localizedData.optionsFields.aspect_ratio}
                </label>
                <select
                  className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
                  name="Tire Aspect Ratio"
                  id="tireSizeAspectRatio"
                  value={aspectRatioValue}
                  disabled={true}
                  aria-label={localizedData.optionsFields.aspect_ratio}
                  onChange={(e) => setaspectRatioValue(e.target.value)}
                >
                  <option value="">{localizedData.optionsFields.aspect_ratio}</option>
                </select>
              </div>
            )}
            {aspectRatioValue ? (
              <TireRim localizedData={localizedData} widthValue={widthValue} aspectRatioValue={aspectRatioValue} rimValue={rimValue} setrimValue={handleSelectTrimValue} />
            ) : (
              <div className="w-full">
                <label className="sr-only" htmlFor="tireSizeRimSize">
                  {localizedData.optionsFields.rim_size}
                </label>
                <select
                  className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
                  name="size"
                  id="tireSizeRimSize"
                  value={rimValue}
                  disabled={true}
                  aria-label={localizedData.optionsFields.rim_size}
                >
                  <option value="">{localizedData.optionsFields.rim_size}</option>
                </select>
              </div>
            )}
          </div>
        </div>
        {showSubmitButton &&
          (rimValue ? (
            <button
              onClick={handleSubmitForm}
              disabled={submitting}
              className={`${
                submitting ? 'loading' : ''
              } has-cherry-to-crimson-horizontal-gradient-background icon-wheel-for-btn ml-auto flex cursor-pointer items-center rounded-r-full px-4 py-2 font-bold uppercase text-white md:absolute md:right-0 md:bottom-0 md:m-10 `}
            >
              {submitting ? <span>{localizedData.optionsFields.searching_for_tires}...</span> : <span>{localizedData.optionsFields.find_tires}</span>}
            </button>
          ) : (
            <button
              disabled={submitting}
              className="bg-medium-gray icon-wheel-for-btn ml-auto flex cursor-not-allowed items-center rounded-r-full px-4 py-2 font-bold uppercase text-white md:absolute md:right-0 md:bottom-0 md:m-10"
            >
              <span>{localizedData.optionsFields.find_tires}</span>
            </button>
          ))}
      </form>
    </div>
  );
}
