import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function VehicleOption({ localizedData, yearValue, makeValue, modelValue, optionValue, setOptionValue, setChassis, setModel }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOption = async () => {
    setLoading(true);
    const { data } = await axios.get(`/wp-json/stm/v1/lookup/vehicle/body-type?year=${yearValue}&manufacturer=${makeValue}&model=${modelValue}`);
    console.log(data);
    setOptions(data);
    setLoading(false);
  };

  const handleChange = (value) => {
    const filteredOption = options.find((option) => option.SubModel === value);
    setOptionValue(value);
    setChassis(filteredOption.DRChassisID);
    setModel(filteredOption.DRModelID);
  };

  useEffect(() => {
    fetchOption();
  }, [modelValue]);

  return (
    <div className="w-full md:w-6/12">
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="brand"
        value={optionValue}
        disabled={loading}
        aria-label={localizedData.optionsFields.option}
        onChange={(e) => handleChange(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.option}</option>}
        {options?.map((option) => {
          return (
            <option value={option.SubModel} key={option.SubModel}>
              {option.SubModel}
            </option>
          );
        })}
      </select>
    </div>
  );
}
