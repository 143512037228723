import React, { useState } from 'react';

import VehicleCommercial from './VehicleCommercial';
import VehicleRetail from './VehicleRetail';

export default function TireVehicle(props) {
  const { preselectedType, onSelectSize, showSubmitButton } = props;
  const { localizedData } = window;
  const [brandType, setbrandType] = useState(preselectedType || 'Retail');
  const [loading, setLoading] = useState(true);
  const [submitting, setsubmitting] = useState(false);

  const HandleBrandType = (e) => {
    setbrandType(e.target.value);
  };

  return (
    <div>
      {!preselectedType && (
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="mb-4">
            <label className="mr-4 mb-4 inline-block">
              <input value="Retail" checked={brandType.toLowerCase() === 'retail'} onChange={(value) => HandleBrandType(value)} type="radio" className="mr-2" />
              <span>{localizedData.optionsFields.car_truck_suv}</span>
            </label>
            <label className="mr-4 mb-4 inline-block">
              <input value="Commercial" checked={brandType.toLowerCase() === 'commercial'} onChange={(value) => HandleBrandType(value)} type="radio" className="mr-2" />
              <span>{localizedData.optionsFields.commercial_fleet_rv}</span>
            </label>
          </div>
        </form>
      )}

      {brandType.toLowerCase() === 'retail' ? (
        <VehicleRetail
          localizedData={localizedData}
          loading={loading}
          setLoading={setLoading}
          submitting={submitting}
          setsubmitting={setsubmitting}
          onSelectSize={onSelectSize}
          showSubmitButton={showSubmitButton}
        />
      ) : (
        <VehicleCommercial
          localizedData={localizedData}
          loading={loading}
          setLoading={setLoading}
          submitting={submitting}
          setsubmitting={setsubmitting}
          onSelectSize={onSelectSize}
          showSubmitButton={showSubmitButton}
        />
      )}
    </div>
  );
}
