import $ from 'jquery';

export default function reviews() {
  $('.single-product-reviews-submit-a-review').on('click', () => {
    $('#review_form_wrapper').stop(true).slideDown(215);
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('#respond').offset().top - 200,
      },
      500
    );
  });
}
