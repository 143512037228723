import React from 'react'

function LocationDetailsPortalText({
    setShowLocationDetailsModal,
    localizedData
}) {
  return (
    <div>
        <a
            onClick={() => setShowLocationDetailsModal(true)}
            aria-haspopup="true"
            href="#"
            className="font-secondary hover:underline">
            Details
        </a>
    </div>
  )
}

export default LocationDetailsPortalText