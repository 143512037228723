import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function VehicleModel({ localizedData, yearValue, makeValue, modelValue, setModelValue }) {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const fetchModel = async () => {
    setLoading(true);
    const { data } = await axios.get(`/wp-json/stm/v1/lookup/vehicle/models?year=${yearValue}&manufacturer=${makeValue}`);
    setModels(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchModel();
  }, [makeValue]);

  return (
    <div className="w-full md:w-6/12 md:pr-4">
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="brand"
        value={modelValue}
        aria-label={localizedData.optionsFields.model}
        disabled={loading}
        onChange={(e) => setModelValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.model}</option>}
        {models && models.map((model) => {
          return (
            <option value={model.Model} key={model.Model}>
              {model.Model}
            </option>
          );
        })}
      </select>
    </div>
  );
}
