import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function VehiclePosition({ localizedData, applicationsValue, positionValue, setpositionValue }) {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPositions = async () => {
    setLoading(true);
    const { data } = await axios.get(`/wp-json/stm/v1/lookup/veh-commercial/position?application=${applicationsValue}`);
    setPositions(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchPositions();
  }, [applicationsValue]);

  return (
    <div className="w-full">
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="Position"
        value={positionValue}
        disabled={loading}
        aria-label={localizedData.optionsFields.position}
        onChange={(e) => setpositionValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.position}</option>}
        {positions && positions.map((position) => {
          return (
            <option value={position} key={position}>
              {position}
            </option>
          );
        })}
      </select>
    </div>
  );
}
