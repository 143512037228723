import React, { useEffect, useState } from 'react';

import TireSize from './TireSize';
import TireVehicle from './TireVehicle';

interface Props {
  preselectedType: string;
  productsJson: string;
}

interface Product {
  id: number;
  url: string;
  title: string;
  rimWidth: string;
  aspectRatio: string;
  tireSize: string;
  sectionWidth: string;
}

export default function TireModelLookup(props: Props) {
  const { preselectedType, productsJson } = props;
  const products: Product[] = productsJson ? JSON.parse(productsJson) : [];

  const [tabSelected, settabSelected] = useState('Vehicle');
  const [selectedTireSize, setSelectedTireSize] = useState('');
  const [manualSelectTireSize, setManualSelectTireSize] = useState('');
  const [finishedTireSize, setFinishedTireSize] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [foundProduct, setFoundProduct] = useState(null);
  const { localizedData } = window;

  const handleSubmitForm = () => {
    setSubmitting(true);
    window.location.href = foundProduct.url;
  };

  const handleTireSizeSelection = ({ aspectRatio, width, rim }) => {
    const product = products.find((m) => m.aspectRatio === aspectRatio && m.sectionWidth === width && m.rimWidth === rim);
    setFoundProduct(product);
    setFinishedTireSize(true);
    setManualSelectTireSize(product?.tireSize ?? '');
  };

  useEffect(() => {
    setFinishedTireSize(false);
  }, [tabSelected]);

  useEffect(() => {
    const product = products.find((m) => m.tireSize === manualSelectTireSize);
    setFoundProduct(product);
    setManualSelectTireSize(product?.tireSize ?? '');
  }, [selectedTireSize, manualSelectTireSize]);

  const showNotFoundError = !foundProduct && (selectedTireSize || finishedTireSize);

  return (
    <div className="flex flex-wrap md:-mx-5">
      {preselectedType && preselectedType == 'commercial' ? '' : (
        <div id="tire-lookup" className="w-full md:w-8/12 md:px-5 ">
          <div className="h-full w-full justify-center text-black ">
            <div className="text-dark-gray flex text-center font-bold" role="tablist">
              <div
                role="tab"
                onClick={() => settabSelected('Vehicle')}
                className={`${tabSelected === 'Vehicle' ? 'bg-white' : 'bg-medium-gray'} w-1/3 cursor-pointer rounded-t-lg p-2 shadow-inner z-10`}
                aria-selected={tabSelected === 'Vehicle'}
              >
                {localizedData.optionsFields.by_vehicle}
              </div>
            </div>
            <div className={`lookup-min relative bg-white p-10 shadow ${showNotFoundError ? 'pb-24 md:pb-10' : ''}`}>
              {tabSelected === 'Vehicle' && <TireVehicle preselectedType={preselectedType} products={products} onSelectSize={(val) => setSelectedTireSize(val)} showSubmitButton={false} />}
              {showNotFoundError && (
                <p className="text-secondary border-secondary absolute right-0 bottom-0 m-5 inline rounded border-l-4 bg-white py-3 px-4 shadow-md">{localizedData.optionsFields.product_not_found}</p>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="w-full pt-10 md:w-4/12 md:px-5 md:pt-0">
        {preselectedType && preselectedType == 'commercial' ? '' : (
          <label htmlFor="manualSelectTireSize">{localizedData.optionsFields.or_select_tire_size}</label>
        )}
        <select
          className="text-small text-small border-dark-gray mb-8 w-full border px-2 py-1 text-black md:mb-4"
          name="Tire Width"
          id="manualSelectTireSize"
          value={manualSelectTireSize}
          aria-label={localizedData.optionsFields.select_a_tire_size}
          onChange={(e) => setManualSelectTireSize(e.target.value)}
        >
          <option value="">- {localizedData.optionsFields.select_a_tire_size} -</option>
          {products && products.map((product) => {
            return (
              <option value={product.tireSize} key={product.tireSize}>
                {product.tireSize}
              </option>
            );
          })}
        </select>
        {foundProduct ? (
          <button
            onClick={handleSubmitForm}
            disabled={submitting}
            className={`${
              submitting ? 'loading' : ''
            } has-cherry-to-crimson-horizontal-gradient-background icon-wheel-for-btn ml-auto flex cursor-pointer items-center rounded-r-full px-4 py-2 font-bold uppercase text-white`}
          >
            <span>{localizedData.optionsFields.continue_text}</span>
          </button>
        ) : (
          <button disabled={submitting} className="bg-medium-gray icon-wheel-for-btn ml-auto flex cursor-not-allowed items-center rounded-r-full px-4 py-2 font-bold uppercase text-white">
            <span>{localizedData.optionsFields.continue_text}</span>
          </button>
        )}
      </div>
    </div>
  );
}
