import React from 'react';

import DownloadPDF from './DownloadPDF';

export default function LocationQuickView({
  localizedData,
  setShowChangeLocationModal,
  cookies,
  setShowLocationDetailsModal,
  handleSetShowChangeLocationModal,
  handleSetShowLocationDetailsModal,
  loadingPreferredLocation,
}) {
  return (
    <div className="relative mr-8 hidden items-center md:flex">
      {loadingPreferredLocation && (
        <div className="absolute inset-0 z-30 flex h-full w-full items-center justify-center bg-white opacity-75">
          <img className="h-6 w-6 animate-spin" src={`${localizedData.themeUri}/dist/images/wheel-red.svg`} alt={localizedData.optionsFields.header_labels.loading} />
        </div>
      )}
      <div className={`${cookies.stm_preferred_location ? '' : 'w-full'} mr-4 flex flex-col justify-between items-start`}>
        <div className="font-secondary relative font-bold">
          <img className="absolute top-0 left-0 -ml-6 mt-1 h-4 w-4" src={`${localizedData.themeUri}/dist/images/your-location.svg`} alt="" />
          {cookies.stm_preferred_location && localizedData.optionsFields.header_labels.your_location}
        </div>
        {localizedData.optionsFields.locations_map && cookies.stm_preferred_location && (
          <a
            href={`${localizedData.optionsFields.locations_map.url}`}
            target={`${localizedData.optionsFields.locations_map.target}`}
            className="text-secondary mb-1 inline-block text-sm underline hover:no-underline"
          >
            {localizedData.optionsFields.locations_map.title}
          </a>
        )}
        <a
          onClick={() => setShowChangeLocationModal(true)}
          onKeyPress={() => handleSetShowChangeLocationModal(event, true)}
          href="#"
          aria-haspopup="true"
          tabIndex={0}
          className="change-location-trigger text-secondary mb-1 inline-block text-sm underline hover:no-underline"
        >
          {cookies.stm_preferred_location ? localizedData.optionsFields.header_labels.your_location_change : localizedData.optionsFields.header_labels.find_a_location_modal_header}
        </a>
        {localizedData.optionsFields.locations_map && !cookies.stm_preferred_location && (
          <a
            href={`${localizedData.optionsFields.locations_map.url}`}
            target={`${localizedData.optionsFields.locations_map.target}`}
            className="text-secondary mb-1 inline-block text-sm underline hover:no-underline"
          >
            {localizedData.optionsFields.locations_map.title}
          </a>
        )}
        <DownloadPDF localizedData={localizedData} offset={cookies.stm_preferred_location} />
      </div>
      <div className={`${cookies.stm_preferred_location ? '' : 'w-0'}`}>
        {cookies.stm_preferred_location && (
          <>
            <address className="font-secondary text-xs not-italic leading-none">
              <div className="font-light">{cookies.stm_preferred_location.address_line_1}</div>
              <div className="font-light">
                {cookies.stm_preferred_location.address_city}, {cookies.stm_preferred_location.address_region} {cookies.stm_preferred_location.address_postal_code}
              </div>
            </address>
            <div className="font-secondary text-xs font-light leading-none">
              {Array.isArray(cookies.stm_preferred_location.labels)
                ? cookies.stm_preferred_location.labels.filter((label) => label.includes('Cat')).map((category, index) => (index ? ', ' : '') + category.replace('Cat - ', ''))
                : cookies.stm_preferred_location.labels
                    .split(',')
                    .filter((label) => label.includes('Cat'))
                    .map((category, index) => (index ? ', ' : '') + category.replace('Cat - ', ''))}
            </div>
            <a
              onClick={() => setShowLocationDetailsModal(event, true)}
              onKeyPress={() => handleSetShowLocationDetailsModal(event, true)}
              href="#"
              aria-haspopup="true"
              tabIndex={0}
              className="location-details-trigger text-secondary text-sm leading-none hover:underline"
            >
              {localizedData.optionsFields.header_labels.my_location_hours_and_details}
            </a>
          </>
        )}
      </div>
    </div>
  );
}
