import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactDOM from 'react-dom';

import CommercialAccountForm from '../CommercialAccount/CommercialAccountForm';
import CartLocationDetails from './CartLocationDetails';
import ChangeLocation from './ChangeLocation';
import ChangeLocationPortalButton from './ChangeLocationPortalButton';
import ChangeLocationPortalText from './ChangeLocationPortalText';
import CheckoutLocationDetails from './CheckoutLocationDetails';
import CustomCheckoutButton from './CustomCheckoutButton';
import DownloadPDF from './DownloadPDF';
import FindALocationPortalButton from './FindALocationPortalButton';
import FindALocationWithPageRefresh from './FindALocationWithPageRefresh';
import LocationDetails from './LocationDetails';
import LocationDetailsPortalText from './LocationDetailsPortalText';
import LocationQuickView from './LocationQuickView';
import MakeThisMyStorePortalButton from './MakeThisMyStorePortalButton';
import MakeThisMyStorePortalText from './MakeThisMyStorePortalText';
import PhoneCTAPortal from './PhoneCTAPortal';
import YourLocationMobile from './YourLocationMobile';

const url = window.location.origin;
const postbaseURL = `${url}`;

const client = axios.create({
  baseURL: postbaseURL,
});

const searchClient = axios.create({
  baseURL: 'https://api.mapbox.com',
});

export default function YourLocation({ customerType }) {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['stm_preferred_location']);
  const [locations, setLocations] = useState(null);
  const [locationPosts, setLocationPosts] = useState([]);
  const [distances, setDistances] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [locationInput, setLocationInput] = useState('');
  const [filter, setFilter] = useState('');
  const [showChangeLocationModal, setShowChangeLocationModal] = useState(false);
  const [showLocationDetailsModal, setShowLocationDetailsModal] = useState(false);
  const [locationDetails, setLocationDetails] = useState({});
  const locationInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingPreferredLocation, setLoadingPreferredLocation] = useState(false);
  const [redirectAfterLocationSelected, setRedirectAfterLocationSelected] = useState(false);
  const [message, setMessage] = useState('');
  const focusElsList =
    'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)';

  useEffect(() => {
    if (showChangeLocationModal || showLocationDetailsModal) {
      document.getElementsByTagName('html')[0].classList.add('overflow-hidden');
      document.querySelector('.menu-icon').classList.remove('z-50');

      if (showChangeLocationModal) {
        removeAllFocus();

        const changeLocationModal = document.querySelector('.change-location-wrapper');
        const focusableChangeLocationModalEls = changeLocationModal.querySelectorAll(focusElsList);

        focusableChangeLocationModalEls.forEach((el) => {
          el.tabIndex = 0;
        });
      }

      if (showLocationDetailsModal) {
        removeAllFocus();

        const locationDetailsModal = document.querySelector('.location-details-wrapper');
        const focusableLocationDetailsModalEls = locationDetailsModal.querySelectorAll(focusElsList);

        focusableLocationDetailsModalEls.forEach((el) => {
          el.tabIndex = 0;
        });
      }
    } else {
      document.getElementsByTagName('html')[0].classList.remove('overflow-hidden');
      document.querySelector('.menu-icon').classList.add('z-50');
      restoreAllFocus();
    }
  }, [showChangeLocationModal, showLocationDetailsModal]);

  const removeAllFocus = () => {
    const focusableEls = document.querySelectorAll(focusElsList);

    focusableEls.forEach((el) => {
      el.tabIndex = -999;
    });
  };

  const restoreAllFocus = () => {
    const focusableEls = document.querySelectorAll(focusElsList);

    focusableEls.forEach((el) => {
      el.tabIndex = 0;
    });
  };

  const handleSetPreferredLocation = (e) => {
    const preferredLocationObject = {
      yextEntityId: e.target.getAttribute('data-entity-id'),
    };
    console.log(e);
    console.log(preferredLocationObject);

    setLoadingPreferredLocation(true);
    getLocationPost(preferredLocationObject.yextEntityId);
    console.log(getLocationPost);
  };

  const handleLocationInputChange = async (e) => {
    setLocationInput(e.currentTarget.value);
    setMessage('');
  };

  const handleSetFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleSetShowLocationDetailsModal = (e, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setShowLocationDetailsModal(value);
    }
  };

  const handleSetShowChangeLocationModal = (e, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setShowChangeLocationModal(value);
    }
  };

  const getAllLocationPosts = async () => {
    const response = await client
      .get('/wp-json/stm/v1/getAllLocationEntityIds', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setLocationPosts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAllLocationPosts();
  }, []);

  const getLocationPost = async (entityId) => {
    const response = await client
      .get('/wp-json/stm/v1/getLocationByEntityId', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          entityId,
        },
      })
      .then((response) => {
        setLocationDetails(response.data);
        const preferredLocationCookie = response.data;
        console.log(preferredLocationCookie);

        setCookie('stm_preferred_location', JSON.stringify(preferredLocationCookie), {
          path: '/',
        });

        if (redirectAfterLocationSelected) {
          location.reload();
        } else {
          setShowChangeLocationModal(false);
          setShowLocationDetailsModal(true);
          setLoadingPreferredLocation(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingPreferredLocation(false);
      });
  };

  const getLocationsByCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        const lat = `${position.coords.latitude}`;
        const long = `${position.coords.longitude}`;
        getLocations(lat, long);
        setLocationInput('Your Location');
      });
    } else {
      console.log(localizedData.optionsFields.header_labels.geolocation_not_available);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const response = await searchClient
      .get(`/geocoding/v5/mapbox.places/${locationInput}.json`, {
        params: {
          access_token: 'pk.eyJ1Ijoic3RtLW1hcHMiLCJhIjoiY2xzYXR1aWhpMDVhNTJrcXVoNmtpajRhZCJ9.UYPqYh7cj1l9VTdnc3qjKw',
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      .then((response) => {
        // console.log(response.data.features[0]);
        const lat = response.data.features[0].geometry.coordinates[1];
        const long = response.data.features[0].geometry.coordinates[0];
        getLocations(lat, long);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const getLocations = async (lat, long) => {
    // if (location.trim() == '') {
    //   setMessage(localizedData.optionsFields.header_labels.please_enter_a_location);

    //   return;
    // }

    setLoading(true);

    const locations = null;
    const distances = null;
    let searchFilter = null;

    switch (filter) {
      case 'c_commercialServices':
        searchFilter = 'Cat - Commercial';
        break;
      case 'c_retailServices':
        searchFilter = 'Cat - Retail';
        break;
      case 'stmfj':
        searchFilter = 'STMPFJ';
        break;
      default:
        searchFilter = null;
        break;
    }

    const response = await client
      .get('/wp-json/stm/v1/searchLocations', {
        params: {
          lat,
          long,
          filter: searchFilter,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const locations = response.data;
        const locationsEntities = locations.map((location) => location.entity_id);
        const filteredLocations = locations.filter((location) => {
          if (location) {
            const isIncluded = locationPosts.includes(location.entity_id);
            return isIncluded;
          }

          return false;
        });
        // distances = response.data.response.distances;
        // setLocations(filteredLocations);
        setLocations(locations);
        // setDistances(distances);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    setHasSearched(true);
  };

  const resetLocationInput = () => {
    setLocationInput('');
    if (locationInputRef.current) {
      locationInputRef.current.focus();
    }
  };

  const { localizedData } = window;

  // Count how many nearby locations are on the page to render portals for.
  const MTMSTextNodes = Array.from(document.querySelectorAll('.MakeThisMyStorePortalText'));
  const FindALocationDOMNodes = Array.from(document.querySelectorAll('.FindALocationDOMNode'));

  const FindALocationWithPageRefreshDOMNodes = Array.from(document.querySelectorAll('.FindALocationWithPageRefreshDOMNode'));

  const CustomCheckoutButtonDOMNodes = Array.from(document.querySelectorAll('.CustomCheckoutButtonDOMNode'));

  const CartLocationDetailsDOMNodes = Array.from(document.querySelectorAll('.CartLocationDetailsDOMNode'));

  const DownloadPDFDiv = document.getElementById('DownloadPDF');

  const YourLocationMobileDOMNode = document.getElementById('YourLocationMobile');

  const PhoneCTAPortalDOMNode = document.getElementById('PhoneCTAPortalDOMNode');

  const CommercialAccountDiv = document.getElementById('CommercialAccountForm');

  return (
    <div>
      <LocationQuickView
        localizedData={localizedData}
        setShowChangeLocationModal={setShowChangeLocationModal}
        cookies={cookies}
        setShowLocationDetailsModal={setShowLocationDetailsModal}
        handleSetShowChangeLocationModal={handleSetShowChangeLocationModal}
        handleSetShowLocationDetailsModal={handleSetShowLocationDetailsModal}
        loadingPreferredLocation={loadingPreferredLocation}
      />

      <ChangeLocation
        customerType={customerType}
        message={message}
        setMessage={setMessage}
        localizedData={localizedData}
        setShowChangeLocationModal={setShowChangeLocationModal}
        setShowLocationDetailsModal={setShowLocationDetailsModal}
        showChangeLocationModal={showChangeLocationModal}
        handleSetShowChangeLocationModal={handleSetShowChangeLocationModal}
        getLocationsByCurrentLocation={getLocationsByCurrentLocation}
        hasSearched={hasSearched}
        locationInput={locationInput}
        locationInputRef={locationInputRef}
        locations={locations}
        resetLocationInput={resetLocationInput}
        submitForm={submitForm}
        handleLocationInputChange={handleLocationInputChange}
        handleSetFilter={handleSetFilter}
        filter={filter}
        handleSetPreferredLocation={handleSetPreferredLocation}
        distances={distances}
        loading={loading}
        loadingPreferredLocation={loadingPreferredLocation}
        setRedirectAfterLocationSelected={setRedirectAfterLocationSelected}
      />

      <LocationDetails
        cookies={cookies}
        localizedData={localizedData}
        showLocationDetailsModal={showLocationDetailsModal}
        setShowLocationDetailsModal={setShowLocationDetailsModal}
        setShowChangeLocationModal={setShowChangeLocationModal}
        handleSetShowLocationDetailsModal={handleSetShowLocationDetailsModal}
        handleSetShowChangeLocationModal={handleSetShowChangeLocationModal}
      />

      {document.getElementById('LocationDetailsPortalTextDOMNode') &&
        ReactDOM.createPortal(
          <LocationDetailsPortalText setShowLocationDetailsModal={setShowLocationDetailsModal} localizedData={localizedData} />,
          document.getElementById('LocationDetailsPortalTextDOMNode')
        )}

      {document.getElementById('ChangeLocationPortalButtonDOMNode') &&
        ReactDOM.createPortal(
          <ChangeLocationPortalButton setShowChangeLocationModal={setShowChangeLocationModal} localizedData={localizedData} />,
          document.getElementById('ChangeLocationPortalButtonDOMNode')
        )}

      {document.getElementById('ChangeLocationPortalTextDOMNode') &&
        ReactDOM.createPortal(
          <ChangeLocationPortalText setShowChangeLocationModal={setShowChangeLocationModal} localizedData={localizedData} />,
          document.getElementById('ChangeLocationPortalTextDOMNode')
        )}

      {document.getElementById('MakeThisMyStorePortalButtonDOMNode') &&
        ReactDOM.createPortal(
          <MakeThisMyStorePortalButton handleSetPreferredLocation={handleSetPreferredLocation} localizedData={localizedData} loadingPreferredLocation={loadingPreferredLocation} cookies={cookies} />,
          document.getElementById('MakeThisMyStorePortalButtonDOMNode')
        )}

      {document.getElementById('CheckoutLocationDetailsDOMNode') &&
        ReactDOM.createPortal(
          <CheckoutLocationDetails setShowChangeLocationModal={setShowChangeLocationModal} localizedData={localizedData} cookies={cookies} />,
          document.getElementById('CheckoutLocationDetailsDOMNode')
        )}

      {MTMSTextNodes &&
        MTMSTextNodes.map(
          (node, index) =>
            document.getElementById(`MakeThisMyStorePortalTextDOMNode${index}`) &&
            ReactDOM.createPortal(
              <MakeThisMyStorePortalText handleSetPreferredLocation={handleSetPreferredLocation} localizedData={localizedData} index={index} cookies={cookies} />,
              document.getElementById(`MakeThisMyStorePortalTextDOMNode${index}`)
            )
        )}

      {FindALocationDOMNodes &&
        FindALocationDOMNodes.map(
          (node, index) =>
            node && ReactDOM.createPortal(<FindALocationPortalButton setShowChangeLocationModal={setShowChangeLocationModal} localizedData={localizedData} btnColor={node.dataset.color} />, node)
        )}

      {FindALocationWithPageRefreshDOMNodes &&
        FindALocationWithPageRefreshDOMNodes.map(
          (node, index) =>
            node &&
            ReactDOM.createPortal(
              <FindALocationWithPageRefresh
                setShowChangeLocationModal={setShowChangeLocationModal}
                setRedirectAfterLocationSelected={setRedirectAfterLocationSelected}
                localizedData={localizedData}
              />,
              node
            )
        )}

      {CustomCheckoutButtonDOMNodes &&
        CustomCheckoutButtonDOMNodes.map(
          (node, index) =>
            node &&
            ReactDOM.createPortal(
              <CustomCheckoutButton
                cookies={cookies}
                localizedData={localizedData}
                setShowChangeLocationModal={setShowChangeLocationModal}
                handleSetShowChangeLocationModal={handleSetShowChangeLocationModal}
              />,
              node
            )
        )}

      {CartLocationDetailsDOMNodes &&
        CartLocationDetailsDOMNodes.map(
          (node, index) =>
            node &&
            ReactDOM.createPortal(
              <CartLocationDetails
                cookies={cookies}
                localizedData={localizedData}
                setShowChangeLocationModal={setShowChangeLocationModal}
                setShowLocationDetailsModal={setShowLocationDetailsModal}
                locationDetails={locationDetails}
              />,
              node
            )
        )}

      {YourLocationMobileDOMNode &&
        ReactDOM.createPortal(
          <YourLocationMobile
            cookies={cookies}
            localizedData={localizedData}
            setShowChangeLocationModal={setShowChangeLocationModal}
            setShowLocationDetailsModal={setShowLocationDetailsModal}
            showChangeLocationModal={showChangeLocationModal}
            getLocationsByCurrentLocation={getLocationsByCurrentLocation}
            hasSearched={hasSearched}
            locationInput={locationInput}
            locationInputRef={locationInputRef}
            locations={locations}
            resetLocationInput={resetLocationInput}
            submitForm={submitForm}
            handleLocationInputChange={handleLocationInputChange}
            handleSetFilter={handleSetFilter}
            handleSetPreferredLocation={handleSetPreferredLocation}
            distances={distances}
            loading={loading}
            loadingPreferredLocation={loadingPreferredLocation}
            setRedirectAfterLocationSelected={setRedirectAfterLocationSelected}
          />,
          YourLocationMobileDOMNode
        )}

      {DownloadPDFDiv && ReactDOM.createPortal(<DownloadPDF localizedData={localizedData} />, DownloadPDFDiv)}

      {PhoneCTAPortalDOMNode &&
        ReactDOM.createPortal(
          <PhoneCTAPortal
            cookies={cookies}
            localizedData={localizedData}
            loadingPreferredLocation={loadingPreferredLocation}
            setShowChangeLocationModal={setShowChangeLocationModal}
            showChangeLocationModal={showChangeLocationModal}
            handleSetShowChangeLocationModal={handleSetShowChangeLocationModal}
          />,
          PhoneCTAPortalDOMNode
        )}

      {CommercialAccountDiv &&
        ReactDOM.createPortal(
          <CommercialAccountForm
            cookies={cookies}
            setShowChangeLocationModal={setShowChangeLocationModal}
            setRedirectAfterLocationSelected={setRedirectAfterLocationSelected}
            localizedData={localizedData}
          />,
          CommercialAccountDiv
        )}
    </div>
  );
}
