import React from 'react'

function ChangeLocationPortalButton({
    setShowChangeLocationModal,
    localizedData
}) {
  return (
    <span
    onClick={() => setShowChangeLocationModal(true)}
    aria-haspopup="true"
    className="cursor-pointer inline-block font-secondary text-primary hover:underline">
      {localizedData.optionsFields.header_labels.my_location_change_location}
    </span>
  )
}

export default ChangeLocationPortalButton