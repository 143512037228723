import '../styles/main.css';
import '../styles/tailwind.css';
import 'vite/modulepreload-polyfill';
import './react-init.tsx';

import {Header, Menu, Search} from './header';

import filterBar from './components/woo/filterBar';
import reviews from './components/woo/reviews';
import cart from './components/woo/cart';
import printQuote from './components/woo/printQuote';
import Dropdowns from './blocks/dropdowns';
import ButtonIcons from './blocks/buttonIcon';
import FileField from './blocks/fileField';
import aria from './ada/aria';
import careersSelect from './ada/careersSelect';


Header();
Menu();
Search();
filterBar();
reviews();
cart();
printQuote();
aria();
careersSelect();

const dropdowns = new Dropdowns();
const buttonIcons = new ButtonIcons();
const fileField = new FileField();

//Cookie notice Close button replace to Button
function replaceSpanWithButton() {
    const closeIcon = document.querySelector('#cn-close-notice');

    if (!closeIcon) return;
        // Create a new button element
        const button = document.createElement('button');

        // Copy properties and attributes from the old element
        button.innerHTML = closeIcon.innerHTML;
        button.className = closeIcon.className;
        button.id = closeIcon.id;
        button.setAttribute('data-cookie-set', closeIcon.getAttribute('data-cookie-set'));
        button.setAttribute('title', closeIcon.getAttribute('title'));
        button.setAttribute('aria-label', 'Close');

        // Replace the old element with the new one
        closeIcon.parentNode.replaceChild(button, closeIcon);
    }

// Call the function to replace the span with the button
replaceSpanWithButton();

