import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function VehicleModelYear({ localizedData, yearValue, setyearValue }) {
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchYears = async () => {
    setLoading(true);
    const { data } = await axios.get('/wp-json/stm/v1/lookup/vehicle/years');
    setYears(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchYears();
  }, [setYears]);

  return (
    <div className="w-full md:w-6/12 md:pr-4">
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="Model Year"
        value={yearValue}
        aria-label={localizedData.optionsFields.model_year}
        disabled={loading}
        onChange={(e) => setyearValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.model_year}</option>}
        {years && years.map((year) => {
          return (
            <option value={year.Year} key={year.Year}>
              {year.Year}
            </option>
          );
        })}
      </select>
    </div>
  );
}
